/* navbar */
.buyPage-background {
  background-color: var(--bs-primary);

}

.buyPage {
  background-color: #f4f5f6;
}



/* .buyPageForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 0;
}

.Buy-field input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}




.house-card-container {
  margin-top: 20px;
} */

@media screen and (min-width:900px) {
  .sticky-filter {
    height: 90vh;
    position: sticky;
    top: 0;
    overflow: auto;
  }

  .sticky-filter::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
  }

  .sticky-filter::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }

  .sticky-filter::-webkit-scrollbar-thumb {
    background-color: #929695;
  }

}


@media screen and (min-width: 370px) and (max-width: 550px) {
  .full-width {
    width: 100%;
  }


}

@media screen and (min-width: 10px) and (max-width: 780px) {
  .col-sm-none {
    display: none;
  }

  .center-property {
    justify-content: center;
  }

  /* @media (min-width: 780px) and (max-width: 2000px){
  .hide-searchbar {
    display: none;
  }
} */

  @media (max-width: 780px) {
    .hide-searchbar {
      display: block;
      /* Ensure it's visible on small screens */
    }

  }



  @media (min-width: 781px) {
    .hide-searchbar {
      display: none;
      /* Hide on larger screens */
    }

  }




}

@media screen and (max-width: 1095px) {
  .specification {
    display: none;
  }
}

.filter-icon {
  display: block;
}

@media (min-width: 780px) {
  .filter-icon {
    display: none;
  }
}


/* search bar mobile
.responsiveComponent {
} */

@media (max-width: 763px) {
  .responsiveComponent {
  }
}
@media (min-width: 762px) {
  .responsiveComponent {
    display: none !important; /* Default to hidden */
  }
}


.aestyle {
  background-color: var(--bs-secondary) !important;
  color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}




@media (max-width: 576px) {
  .custom-width-allEmployee{
    width: 80vw;
  }
}

.property-cont {
    background-color: var(--bs-secondary);
    padding: 1rem;

}

.customInput {
    height: 2.5rem;
}

.card-add-property label {
    font-weight: 500;
    color: var(--bs-accent);
    font-size: 0.938rem;
}

.error-text {
    color: red;
    font-size: 0.9em;
    margin-top: 2px;
    margin-bottom: 0;
}

.property-form-header {
    background-color: var(--bs-primary);
}

.card-add-property {
    background-color: var(--bs-primary);
    border: 1px solid var(--bs-secondary);
}

.card-add-property h5 {
    color: var(--bs-accent);
}

.card-add-property input {
    background-color: var(--bs-secondary);
}

.card-add-property input:focus {
    background-color: var(--bs-secondary);
}

.card-add-property textarea:focus {
    background-color: var(--bs-secondary);
}


.card-add-property textarea {
    background-color: var(--bs-secondary);
}

.card-add-property input::placeholder {
    color: gray;
}

.list-color ul li {
    color: var(--bs-accent) !important;
}

.btn-add {
    color: var(--bs-accent);
    border: 1px solid var(--bs-accent);
}

.btn-add:hover {
    color: #fff;
    background-color: var(--bs-accent);
    border: 1px solid var(--bs-accent);
}

.clear-img{
    position: absolute;
    top: 0;
    color: var(--bs-secondary);
    right: 0;
    background-color: var(--bs-accent);
    padding: 0 8px;
    margin: 0 !important;
    cursor: pointer;
    border-radius: 100px;
    font-size: 15px;
}

.img-add{
    flex-wrap: wrap;
    justify-content: space-around;
    height: 200px;
    overflow: auto;
}


  .contactPage-background {
    background-color: var(--bs-primary);
  }
  /* .contact-section-1{
      height: 100vh;
    } */
  
  .background-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("https://img.freepik.com/free-vector/gradient-design-luxury-modern-abstract-3d-background_343694-2969.jpg");
  
    height: 413px;
    background-size: cover;
    background-position: center;
  
    justify-content: center;
  
    align-content: center;
  }
  .contactus {
    height: 413px;
    background-size: cover;
    background-position: center;
  
    justify-content: center;
  
    align-content: center;
    text-align: start;
    color: #f4ebd0;
  }
  
  .contactus .headingone {
    font-size: 1.5rem; /* Adjust font size for headings */
    margin: 20px 10px 22px px; /* Margin around the heading */
    font-weight: bold;
  }
  
  .para1 {
    width: 90%; /* Adjust width for responsiveness */
    max-width: 736px; /* Maximum width for larger screens */
    font-size: 18px; /* Font size */
    text-align: start;
    align-content: flex-start;
  }
  
  /* ---------------------------------------Vertical line--------------------------------------------------------- */
  
  .vertical-line {
    width: 4px;
    height: 100px;
    background-color: var(--bs-primary);
  }
  
  .right-box {
    padding: 40px;
    border-radius: 8px;
    color: var(--bs-primary);
  }
  /* ---------------------------------------Vertical line--------------------------------------------------------- */
  
  @media (max-width: 767px) {
    .contactus {
      height: auto;
      padding: 20px;
    }
  
    .contactus .headingone {
      font-size: 2rem;
    }
  
    .para1 {
      font-size: 0.875rem; /* Smaller font size */
      width: 100%; /* Full width for smaller screens */
      margin: 0 auto; /* Center paragraph */
    }
  
    .vertical-line {
      display: none; /* Hide the vertical line on small screens */
    }
  }

.box-area{
    width: 930px;
}



.right-box{
    padding: 40px 30px 40px 40px;
}



::placeholder{
    font-size: 16px;
}

.rounded-4{
    border-radius: 20px;
}
.rounded-5{
    border-radius: 30px;
}




.input-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
}

.login-form-control {
  width: 100%;
  padding-right: 2.5rem; 
  /* border: none; */
}

.password-toggle-btn {
  position: absolute;
  top: 50%;
  z-index: 2; 
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0; 
}

.password-toggle-btn svg {
  font-size: 1.5rem; 
  color: #555; 
}

  /* .password-toggle-btn {
    position: absolute;
    right: 2.5rem;
    top: 1.2rem;
    transform: translateY(-50%);
    z-index: 1; 
    padding: 0;
  } */
  

@media only screen and (max-width: 768px){

     .box-area{
        margin: 0 10px;

     }
     .left-box{
        height: 200px;
        overflow: hidden;
     }
     .right-box{
        padding: 20px;
     }

}
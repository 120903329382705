   body {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
     font-family: 'Bahnschrift';
   }

   /* Heading Styles */
   h1,
   h2 {
     font-family: 'Bahnschrift' !important;
   }

   @font-face {
    font-family: 'Bahnschrift';
    src: url('./assets/font/Bahnschrift.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  

   /* Subheading Styles */
    
   h3,
   h4,
   h5,
   h6,th {
     font-family: 'Bahnschrift' !important;
   }

   p{
    font-family: 'Bahnschrift' !important;

   }

   :root {
     --bs-primary: #122620;
     --bs-secondary: #F4EBD0;
     --bs-accent: #D6AD60;
   }


   .btn-primary {
     background-color: var(--bs-primary) !important;
     border-color: var(--bs-primary) !important;

   }

   .btn-secondary {
     color: var(--bs-secondary) !important;
     border-color: var(--bs-secondary) !important;

   }

   .btn-accent {
     background-color: var(--bs-accent) !important;
     border-color: var(--bs-accent) !important;
   }

   /* custom.css */
input:focus,
textarea:focus,
select:focus {
  border-color: var(--bs-accent) !important; 
  box-shadow:none !important;

}



   /* .btn-outline-primary {
     background-color: transparent !important;
     border-color: var(--bs-primary) !important;
   }

   .btn-outline-secondary {
    background-color: transparent !important;

     color: var(--bs-secondary) !important;

   }

   .btn-outline-accent {
    background-color: transparent !important;
    border-color: var(--bs-accent) !important;
   } */

   /* .navbar {
    background-color: var(--bs-secondary) !important;
  } */

  .btn-outline-accent{
    background-color: var(--bs-accent) !important;
    border-color: var(--bs-accent) !important;
  }

  .responsive-table th {
    white-space: nowrap;
  }

  .responsive-table{
    overflow: auto !important;
    width: 100% !important;
  }
.propPage-background {
  background-color: var(--bs-primary);

}

.property-margin {
  margin-top: 83px;
}

.slide-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;

}

.large-image {
  width: 100%;
  height: 500px;
}

.small-image {
  width: auto;
  height: 242px;
}

.description-head {
  color: var(--bs-accent);
}


.headingtwo {
  color: var(--bs-primary);
  ;
}



.property-card {
  border: none !important;
  background-color: var(--bs-secondary);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.image-wrapper {
  position: relative;
}



.button-container {
  position: absolute;
  top: 90%;
  left: 5%;
  transform: translateY(-50%);
  display: flex;
  gap: 10px;
}

.overlay-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 30px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.overlay-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.close-button-slider{
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--bs-accent);
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
}


.breadcrumb {
  margin-bottom: 20px;
}


/*  */

.slide-show {
  width: 100%;
  max-width: 100%;
  background: none;
  object-fit: cover;
}

.slide-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  /* Ensures images cover the slide area without distortion */
  display: block;

}

/* Add styling for navigation dots */
.slick-dots {
  bottom: 10px;
}

.slick-dots li button:before {
  color: #000;
  /* Customize the color of the dots */
}

.slick-prev,
.slick-next {
  z-index: 1;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  /* Adjust size of arrows */
}


.property-background {
  background-color: var(--bs-primary);
}


.btn-property {
  background-color: var(--bs-accent);
  color: #fff;
}

/*  */


/* Responsive adjustments */
@media (max-width: 768px) {
  .button-container {
    /* flex-direction: column; */
    top: 80%;
  }

}

@media (max-width: 480px) {
  .large-image {
    width: 100%;
    height: 250px;
  }

}

.left-column {
  flex: 7;
  /* 70% of the container width */
  padding: 15px;
}

.right-column {
  flex: 3;
  /* 30% of the container width */
  padding: 15px;
}

.my-custom-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* Add more custom styles if needed */
}

@media only screen and (min-width: 901px) {
  .agent-card {
    height: 100%;
  }

  .agent-card .agent-sticky {
    position: sticky !important;
    top: 100px !important;
  }
}


.custom-width {
  width: 170px;
  height: 48px;
  align-items: center;
  border-radius: 30px;
}

/* Optional: Add responsive behavior for smaller screens */
@media (max-width: 768px) {
  .my-custom-container {
    flex-direction: column;
  }

  .left-column {
    flex: none;
    width: 100%;
  }
}

@media (max-width: 767.98px) {

  .row.flex-nowrap,
  .right-column {
    flex-wrap: nowrap;
  }
}

@media (max-width: 995px) {
  .small-image {
    display: none;
  }
}

@media (max-width: 780px) {
  .overlay-button span {
    /* display: none; */
    /* position: absolute; */

  }

  .content h2 {
    font-size: 20px;
    font-weight: bold;
  }

  .property-card p {
    font-size: 15px;
  }

  .property-card ul li {
    font-size: 15px;
  }


}

@media (max-width: 334px) {
  .amenities-padding {
    padding: 10px;
  }


}

  /* .contact-call-btn button{
    border: none !important;
  } */

  .btn-property:disabled {
    opacity: 0.6; /* Make the button appear disabled */
    cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
    background-color: var(--bs-accent); /* Optional: Set a background color for disabled state */
    color: var(--bs-disabled-color, #6c757d); /* Optional: Set text color for disabled state */
    border-color: var(--bs-disabled-border, #e9ecef); /* Optional: Set border color for disabled state */
  }

   .modal-content{
    background-color: rgba(0, 0, 0, 0) !important;
  }
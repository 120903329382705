/* .otp-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 24px;
    margin: 0 5px;
}

.otp-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
} */


.Heading-otp {
    color: var(--bs-primary) !important;
  }
  .otp-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 24px;
    margin: 0 5px;
  }
  
  .otp-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  /* Custom CSS */
  .custom-btn {
    background-color: var(--bs-primary) !important; /* Background color */
    color: var(--bs-secondary);
    border-color: var(--bs-primary) !important; /* Border color */
    transition: all 0.3s ease;
  }
  
  .custom-btn:hover {
    background-color: var(--bs-primary);
    color: var(--bs-accent);
  }
.box {
    padding: 20px;
    box-shadow: 0 4px 8px #b4cabc;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    /* box-shadow: 0 4px 8px #09371a; */
    margin: 30px;
    text-align: center;
    /* Optional: Center the text inside the boxes */
}

.box:hover {
    box-shadow: 0 4px 8px #09371a;
}

.box svg {
    color: #D6AD60;

}

.chart-bg {
    background-color: #f8f9fa;
    /* box-shadow: 0 4px 8px #09371a; */
    border-radius: 5px;
    box-shadow: 0 4px 8px #b4cabc;
}

.chart-padding {
    padding: 10px;
}


@media screen and (min-width: 370px) and (max-width: 450px) {
    .search-hide {
        display: none;
    }
}
.nav-header {
  height: 85px;
  padding: 0 0px;
  background: var(--bs-primary);

}

.nav-right-item li a {
  color: var(--bs-accent) !important;
  /* padding-left: 40px !important;
    padding-right: 40px !important; */
}

.nav.active {
  background: var(--bs-primary);
}



.nav-logo img {
  height: 70px;
  width: auto;

}

/*  */
.nav-link {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
  /* Adjust this padding if needed */
  text-decoration: none;
  color: #000;
  /* Default text color */
  font-size: 16px;
  font-weight: 500;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  /* Start with no line */
  height: 2px;
  /* Thickness of the line */
  background-color: var(--bs-accent);
  /* Line color */
  transition: width 0.3s ease;
}

.nav-link:hover::after,
.nav-link.active:hover::after {
  /* Only underline on hover, including active link */
  width: 100%;
  /* Full width on hover */
}

.nav-link.active::after {
  width: 0%;
  /* No underline when the link is active */
}

.offcanvas-header {
  height: 84px;

}

@media screen and (min-width:200px) and (max-width:430px) {
  .nav-header {
    height: 84px;
    padding: 0px 0px;
  }

  .nav-logo img {
    height: 50px;
    width: auto;
  }
}

/* 
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;  Adjust margin to align dropdown properly} */


/* Custom dropdown container */
.custom-nav-item {
  position: relative;
  margin-right: 20px;
}

/* Custom dropdown menu */
.custom-dropdown-menu {
  position: absolute;
  top: 50px;
  left: -52px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  list-style: none;
  min-width: 150px;
  z-index: 1000;
}

/* Dropdown item styles */
.custom-dropdown-menu .dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
  background-color: #fff;
  border: none;
  width: 100%;
  text-align: left;
}

.custom-dropdown-menu .dropdown-item:hover {
  background-color: #f8f9fa;
}



/* -------------profile css---------- */
.custom-offcanvas-close .btn-close {
  color: #fff;
}


.profile-icon {

  font-size: 20px !important;
}


/* off canvas close button */
.offcanvas-header .btn-close {
  background-color: #ffffff; /* Dark gray background color */
  border-radius: 50%;        /* Keep the button circular */
}


/*  navbar search*/
.search-input-navbar {
  width: 300px; /* Adjust the width as needed */
  padding-right: 40px; /* Space for clear button inside */
  border-radius: 20px;
}

.clear-btn-navbar {
  font-size: 18px;
  color: #000;
  cursor: pointer;
  background-color: transparent; /* Ensure no background */
  border: none;
  position: absolute;
}

.search-btn-navbar {
  cursor: pointer;
  background-color: transparent; /* Ensure no background */
  border: none;
  margin-left: 10px; /* Space between input and search icon */
  padding: 0;
}

@media (max-width: 991px) {
  .search-input-navbar {
      display: none; /* Hide on smaller screens */
  }
}


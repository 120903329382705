.terms-background {
    background-color: var(--bs-primary)
}

.terms-page {
    margin-top: 80px;
    background-color: var(--bs-secondary);

}


.terms-box {
    background-color: var(--bs-primary);
}

.terms-box h2 {
    color: var(--bs-secondary);
}

.term-second-box {
    border: 1px solid var(--bs-accent);
}

.term-second-box p {
    color: var(--bs-primary);
}

.terms-page p {
    color: var(--bs-primary);
}

.terms-page ul li {
    color: var(--bs-primary);
}

.terms-page h3 {
    color: var(--bs-primary);
}
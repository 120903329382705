.add-agent-body {
    background-color: var(--bs-secondary);
    font-weight: 400;
    height: 100vh;

}

.add-agent-card {
    background-color: var(--bs-primary);
}

.add-agent-card h5 {
    color: var(--bs-accent);
}



.page-title {
    padding: 0.9375rem 1.875rem;

    margin-bottom: 1.875rem;
    border-radius: 0.5rem;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;

}
.card-box{
    overflow: auto;
    height: 70vh;
}


.card-box {
    padding: 1.875rem;
}

.text-size label {
    font-size: 0.938rem;
    font-weight: 500;
    color: var(--bs-accent);
}

.text-size input {
    padding: .280rem .78rem !important;
    background-color: var(--bs-secondary);
}

.text-size input::placeholder {
    font-size: 14px;
    color: gray;

}

.text-size input:focus {
    background-color: var(--bs-secondary);
}



.text-size select option {
    font-size: 14px;
    /* font-family: 'Poppins', sans-serif; */

}

.text-size select {
    background-color: transparent !important;
    font-weight: 400;
    color: #7e7e7e !important;
    font-size: 14px;
    /* font-family: 'Poppins', sans-serif; */

}
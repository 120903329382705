* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.btn-hoverrr:hover {
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: var(--bs-accent);
    transition: all 0.3s ease;

}

.background-main {
    background-color: var(--bs-primary);
}

.text-color {
    color: var(--bs-accent);
}

.box-color {
    background-color: var(--bs-secondary);
}

.valuation-text {
    color: var(--bs-primary);
}

.text-card-new span {
    color: var(--bs-primary);
}
.text-card span {
    color: var(--bs-accent);
}


/* ---------------------------------hero-page-------------------------------------------- */
.hero {
    background-color: var(--bs-primary);
    background-position: center;
    background-size: cover;
}

.hero-text {
    color: #fff;
}

.hero-text h1 {
    font-size: 36px;
    color: var(--bs-accent);
    margin-top: 170px;
}

.hero-text p {
    font-size: 16px;
    color: var(--bs-secondary);
}

/* new cards */
.property-card {
    border-radius: 20px;
    overflow: hidden;
    background-color: #f6f1e4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-bottom: 20px;
}

.property-image img {
    height: 237px;
    object-fit: cover;
    width: 100%;
}

.property-details {
    padding: 4px 20px 4px 20px
}

.property-price {
    color: var(--bs-primary);
    font-weight: bold;

}

.property-buttons {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: #f6f1e4;
    border-top: 1px solid #ddd;
}

.property-buttons .btn {
    background-color: #d4a951;
    color: #fff;
    /* font-weight: bold; */
    border-radius: 10px;
    width: 48%;
}

/* ---------------------------------------------search-box------------------------------------ */

.reviews {
    margin-top: 10px;
}

.search-bar-container {
    max-width: 557px;
    margin: 0 auto;
    border-radius: 5px;
    /* position: relative;
    bottom: 35vh; */
    padding: 1rem;
    background-color: rgb(15 34 29 / 66%);
    position: relative;
    display: inline-block;
}

.inline {
    display: flex;
}



.search-form {
    flex: 1;
    border: none;
    border-radius: 5px;
    height: 40px;
    padding: 10px;
    font-size: 16px;
    background-color: var(--bs-secondary);
    
}

.search-btn {
    background-color: var(--bs-accent);
    border: none;
    color: white;
    height: 40px;
    padding: 0 20px;
    font-size: 16px;
    border-radius: 5px;
    margin-left: 20px;
}



.explore-btn button {
    border: 1px solid var(--bs-accent);
    padding: 7px 20px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 25px;
    background-color: var(--bs-accent);
}

.clear-btn {
    position: absolute;
    right: 133px; /* Adjust this value to align the clear button */
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 50px;
    cursor: pointer;
    color: #999;
  }
  .clear-btn:hover {
    color: var(--bs-primary);
  }
  
  .search-btn {
    margin-left: 10px;
  } 
/* ----------------------------------------------------explore--------------------------- */
.padding-box {
    padding: 0 20px;
}



.explore-box {
    margin: auto;
    max-width: 864px;
    padding: 0px 0px 36px 0px;
}

.explore-box h3 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 0;
}


.item {

    background-color: #f4ebd0;
    background-repeat: no-repeat;
    border-radius: 8px;
    margin: 0;
    padding: 64px 48px;


}

.item h2 {
    color: var(--bs-primary);
}

.item p {
    color: var(--bs-primary);
}

.border-input {
    border: none;
    box-shadow: none;
    border-radius: 5px !important;
}



.feature-btn {
    margin: 5px;
    border: 1px solid #d3d3d3;

    padding: 10px 20px;
}



.input-group-text {
    padding: 0.375rem 0.3rem !important;

}

.input-control {
    display: block;
    width: 75%;
    padding: .375rem .75rem;
    border: var(--bs-border-width) solid var(--bs-border-color);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-right: 4px;
}


/* --------Featured Property-------- */
.homePage-title-heading{

    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
}


.feature-section {
    margin-top: 95px;
}

.features {
    overflow: hidden;
    height: 0px;
    padding-bottom: 67.5%;
    position: relative;
    width: 100%;
}


.features img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    object-fit: cover;
    width: 100%;
    transition: transform 0.3s ease;

}

/* .feature-card {
    padding: 26px;
} */


.features img:hover {
    transform: scale(1.1);
}

.dot-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;

}

.dot-container span {
    font-size: 13px;
    margin: 0 20px 0 0px;

}

.property-address {
    margin-bottom: none !important;
}

/* -------------valuation-module--- */

.valuation-module {
    padding: 50px 70px 70px;
}

.wrapper img {
    border-radius: 4px;
    height: 90px;
    width: 120px;
    overflow: hidden;
    filter: drop-shadow(4px 4px 6px rgb(23, 23, 23));
}


/* ----------------------------------- */
.accordion {
    background-color: var(--bs-secondary);

}

.accordion h2 {
    color: var(--bs-primary);
}





/* -----------------------------------responsive------------------------ */

@media screen and (min-width: 1019px) and (max-width: 1200px) {
    .valuation-module {
        display: flex !important;
        justify-content: center !important;
        padding: 50px 70px 70px;
        width: 100%;
    }
}


@media (max-width: 768px) {

    .search-bar-container {
        max-width: 400px;

    }

}

@media screen and (min-width: 200px) and (max-width: 427px) {
    .search-bar-container {
        /* max-width: 314px; */
    }

    .search-box {
        padding: 11px 2px 11px 2px;
    }



    .search-form,
    .search-btn {
        height: 40px;
        font-size: 14px;
    }


    .item {
        padding: 28px 32px;
    }


}


@media screen and (min-width: 200px) and (max-width: 991px) {
    .search-property {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
    }

    .valuation-module {
        padding: 34px 34px 34px 34px;
    }

    .feature-btn {
        flex: 0 0 auto;
        min-width: 150px;
    }


    .hero-text h1 {
        font-size: 32px;

    }

    .hero-text p {
        font-size: 16px;


    }

    .explore-box h3 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 0;
    }

    .text-color h3 {
        font-size: 20px;
    }
}

@media screen and (min-width: 0px) and (max-width: 1200px) {
    .carousal-wrapper {
        display: none;
    }
}
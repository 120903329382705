.card-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 3rem;
    /* Adjust height to fit 2 lines of text */
}

.notification-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-position {
    position: relative;
}




.pending-button {
    position: relative;
}

.notification-badge {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}


.listing-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    
}

.card-body {
    flex-grow: 1;
    
}

.card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.page-head button {
    padding: 5px 20px;
    border-radius: 5px;
    border: 1px solid var(--bs-primary);

}

.page-head {
    padding: 0.6rem .5rem;
    background: #fff;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}

.property-list-box {
    padding: 0.9375rem 1.875rem;
    background: #fff;
    margin-bottom: 1.875rem;
    border-radius: 0.5rem;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}



.custom-active {
    background-color: var(--bs-primary);

    border-color: var(--bs-primary);

    color: #fff;

}

.custom-inactive {
    background-color: #f8f9fa;

    border-color: var(--bs-primary);

    color: var(--bs-primary);

}


.custom-inactive:hover {
    background-color: var(--bs-primary);

    border-color: var(--bs-primary);
    color: #fff;

}

.margin-btn {
    margin-right: 12px;
}

.admin-property-buttons {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: #f6f1e4;
    border-top: 1px solid #ddd;
}

.admin-property-buttons .btn {
    background-color: var(--bs-primary);
    border-radius: 10px;
    width: 100%;
    color:var(--bs-accent) !important ;

}

.admin-property-buttons .btn:hover {
    background-color: var(--bs-accent);
    color: var(--bs-primary)  !important  ;
  }

  .admin-property-buttons  .btn:hover {
    background-color: var(--bs-accent);
    color: var(--bs-primary);
  }

/* 
  .admin-listing-icon {
    color: var(--bs-accent);
    transition: color 0.3s ease; 
  }
  
  .admin-listing-icon:hover {
    color: var(--bs-primary); 
  }
   */

@media screen and (min-width: 200px) and (max-width: 513px) {
    .page-head button {
        padding: 5px 7px;
        font-size: 10px;

    }

    .small-font {
        font-size: 14px;
    }

    .page-head {
        padding: 0.9375rem 0rem;
        display: flex;
        justify-content: center;

    }

    .feature-card {
        padding: 10px;
    }
}

@media screen and (min-width: 200px) and (max-width: 991px) {
    .property-list-box {
        padding: 0px;
    }
}

.search-input-container {
    position: relative;
    /* margin: 0 10px; */
    flex-grow: 1;
}

.search-input-container input {
    width: 100%;
    padding-right: 30px; /* Space for the clear icon */
}

.clear-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 34px;
}

@media (max-width: 576px) {
    .search-input-container {
        width: 100%;
        margin-top: 10px;
    }
}

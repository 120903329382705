.aedlistyle {
  background-color: var(--bs-secondary) !important;
  color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  align-items: center;
}
.aedlibtn {
  text-transform: none;
  color: var(--bs-accent);
  padding: 5px;
  background-color: var(--bs-primary);
  border: none;
  border-radius: 3px;
  padding: 5px;
}

.aedlibtn:hover {
  background-color: var(--bs-accent);
  color: var(--bs-primary);
}


.pagecolor{
    background-color: var(--bs-primary)
  }
  
  .pagecolor {
    background-color: var(--bs-primary);
  }
  
  .hero {
    background-color: var(--bs-primary);
  }
  
  .hero-text-about {
    color: var(--bs-accent);
    font-family: "Bahnschrift SemiBold", sans-serif;
    font-weight: "Bold";
  }
  .hero-text1 {
    color: var(--bs-accent);
  }
  
  .hero-text-about p {
    font-size: 20px;
  }
  .contant-headings {
    font-family: "Bahnschrift SemiBold", sans-serif;
  }
  
  
  
  .contant {
    margin: 2rem;
  }
  
  @media screen and (max-width: 992px) {
    .smallscrimg {
      display: none;
    }
    .hero-text-about {
      text-align: center;
    }
  }
  
  @media screen and (max-width: 768px) {
    .contantheading {
      text-align: center;
    }
    .contant {
      text-align: center;
      margin: 0.5rem;
    }
  }